
import { ref, defineComponent, onMounted, computed } from "vue";
import { Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useConfirm } from "primevue/useconfirm";
import Skeletons from "./components/Skeletons.vue";
import SinContenido from "./components/SinContenido.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

interface ReservationDetails {
  reservaId: string;
  segmento: string;
  marca: string;
  modelo: string;
}

interface ViajeDetails {
  id:number;
  segmento: string;
  locacion: string;
  periodo:Array<Date>;
  inicio:Date;
  termino:Date;
  driverId:number;
  clientId?:string;
}

export default defineComponent({
  name: "travelme",
  components: {Field, Form, Skeletons, SinContenido},
  setup() {
    const confirm = useConfirm();
    const router = useRouter();
    const store = useStore();
    const submitButton1 = ref<HTMLElement | null>(null);
    const flotaActual = ref<ReservationDetails>();
    const viajeDetails = ref<ViajeDetails>({driverId:1, id:1, segmento:"", locacion:"", periodo: [moment().toDate(),moment().add(5, 'days').toDate()],
        inicio: moment().toDate(),
        termino: moment().add(5, 'days').toDate()});
    const visibleBottom = ref(false);
    const date1 = ref();
    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      obtenerSucursales();
      obtenerReservasVigentes();
    });
    const saveChanges = () => {
      console.log("x");
      //if (submitButton1.value) {
        console.log("submitButton1");
        // Activate indicator
     //   submitButton1.value.setAttribute("data-kt-indicator", "on");
        obtenerReservasVigentes();
     // }
    };

    const aprobar = (reserva, event) => {
        let solicitud = {
            "viajeId": "",
            "reservaId": reserva.reservaId,
            "nombre": `${currentUser.value.given_name} ${currentUser.value.family_name}`,
            "email": currentUser.value.email,
            "telefono": currentUser.value.phone_number,
            "viajeIda": reserva.solicitaViajeIda,
            "viajeVuelta": reserva.solicitaViajeVuelta,
            "clientId":currentUser.value.clientid, "driverId":currentUser.value.driverId
          };
        confirm.require({
            target: event.currentTarget,
            message: '¿Está seguro de solicitar su asiento en el viaje?',
            icon: 'pi pi-info-circle',
            acceptLabel:"Si",
            rejectLabel:"No",
            acceptClass: 'p-button-danger',
            accept: () => {
                 store
                  .dispatch(Actions.INS_VIAJE_COMPARTIDO, solicitud)
                  .then(() => {
                      Swal.fire({
                        text: "Solicitud Aprobada. Hemos notificado al conductor para que apruebe tu puesto",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Ok!",
                        customClass: {
                          confirmButton: "btn fw-bold btn-light-primary",
                        },
                      }).then(function () {
                        // Go to page after successfully login
                        router.push({ name: "travel-me" });
                        //router.push({ name: "dashboard" });
                      });
                    })
                  .catch(() => {
                    const [error] = Object.keys(store.getters.getEmpresaErrors);
                  });
            },
            reject: () => {
                //toast.add({severity:'error', summary:'Rejected', detail:'You have rejected', life: 3000});
            }
        });
    };

    const sucursales = computed(() => {
      return store.getters.allSucursales;
    });
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const reservas = computed(() => {
      return store.getters.allReservas;
    });
    const loading = ref(true);
    const obtenerSucursales = () => {
      store
        .dispatch(Actions.GET_SUCURSALES, currentUser.value.clientId)
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getCuentaErrors);
        });
    };
    const obtenerReservasVigentes = () => {
      viajeDetails.value.inicio = viajeDetails.value.periodo[0];
      viajeDetails.value.termino = viajeDetails.value.periodo[0];
      if(viajeDetails.value.periodo.length>1)
          viajeDetails.value.termino = viajeDetails.value.periodo[1];
      viajeDetails.value.driverId = currentUser.value.driverId;
      viajeDetails.value.clientId = currentUser.value.clientId;
      store
        .dispatch(Actions.GET_CUPOS_VIAJES_COMPARTIDOS, viajeDetails.value) //currentUser.value.tokenUsuario)
        .then(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getViajeCompartidoErrors);
            Swal.fire({
              text: store.getters.getViajeCompartidoErrors[error],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Intentar nuevamente!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
        });
    };
    const formatDate = (value) => {
      return moment(value).format("MMM D YYYY");
    };
    const formatHour = (value) => {
      return moment(value).format("LT");
    };
    const formatCurrency = (value) => {
      return value.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: "CLP",
      });
    };
    const imagen = (flota) => {
      return `/media/flota/${flota.segmentoVehiculo}/${
        flota.marcaVehiculo
      }/${flota.modeloVehiculo.replace("/", "-")}.png`;
    };

    const imageUrlAlt = (event) => {
      event.target.src = "/media/mock/auto3.png";
    };

    const targetReserva = (reserva) => {
      return `#${reserva.reservaId}`;
    };

    const bgBadge = (reserva) => {
      return reserva.codigoEstado > 0 ? "bg-success" : "bg-info";
    };
    const verFlota = (flota) => {
      visibleBottom.value = true;
      flotaActual.value = flota;
    };

    return {
      loading,
      currentUser,
      reservas,
      formatDate,
      formatCurrency,
      formatHour,
      imagen,
      imageUrlAlt,
      targetReserva,
      bgBadge,
      visibleBottom,
      flotaActual,
      verFlota,aprobar,saveChanges,
      moment,date1,sucursales,viajeDetails
    };
  },
});
